.nw-shopping-list {
	width: 100%;
}

.nw-list {
	list-style-type: none;
	padding: 32px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.load-text {
	text-align: center;
}

.nw-spinner {
	margin: auto;
	margin-bottom: 100px;
}