.div-success, .h2 {
    text-align: center;
    margin: auto;
}

.p-success {    
    text-align: center;
    margin: auto;
    padding: 15px;
}

@media only screen and (max-width: 768px) {
	.logo-web {
		display: none;
	}

    .logo-mobile {
        width: -webkit-fill-available;
    }
}