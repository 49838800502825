.nw-item-cover {
	height: 250px;
	width: 250px;
	border-radius: 20px;
	object-fit: cover;
}

.nw-item {
	margin: 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-transform: capitalize;
}

.nw-item:hover {
	cursor: pointer;
	text-decoration: underline;
}

.nw-item-bottom {
	display: flex;
	text-align: center;
	justify-content: center;
	flex-direction: column;
	font-weight: bold;
	text-transform: capitalize;
}
