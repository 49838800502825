.nw-footer {
	padding: 32px;
	border-top: solid 1px #EEECF2;
	margin-bottom: 20px;
}

.nw-copyright {	
	padding: 32px;
}

.nw-p {
	text-align: center;
	margin: auto;
}

.nw-nav-footer {
	margin: auto;
	cursor: pointer;
	text-align: center;
	align-content: space-between;
}
.nw-nav-footer:hover {
	text-decoration: underline;
	color: #9D8099;
}


.nw-div {
	justify-content: center;
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 768px) {
	.nw-footer {
		margin-bottom: 0px;
        display: flex;
		flex-direction: column;
    }

	.nw-div {
		flex-direction: column;
	}

	.nw-nav-footer {
		margin: 5px;
		text-align: left;
	}
		
}