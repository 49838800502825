.carousel {
    position: relative;
}

.carousel .carousel-status {
    display: none;
}

.carousel .thumb {
    display: none;
}

.carousel .thumbs-wrapper {
    display: none;
}