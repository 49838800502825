.nw-details {
	height: 730px;
    display: flex;
    align-items: center;
	justify-content: center;
}

.list-title {
	display: flex;
    background-color: #291527;
    height: 40px;
    padding-left: 10px;
    align-items: center;
	margin: auto;
	align-content: space-between;
	border-bottom: solid 1px #EEECF2;
}

.list-details {
	padding: 20px;
	background-color: #9D8099;
}

.nw-product {
	display: flex;
	flex-direction: column;
	margin: 30px;
}

.nw-item-cover {
	height: 300px;
    width: 300px;
	border-radius: 20px;
	object-fit: cover;
	
	margin-bottom: 20px;
}

.nw-name-product{
	margin-top: 5px;
	display: flex;
	margin: auto;
}

.nw-price-product {
	text-align: center;
	align-items: center;
	vertical-align: middle;
	margin: auto;
	margin-bottom: 10px;
}

.infos {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
	.nw-details {
		margin-top: 100px;
		margin-bottom: 100px;
        flex-direction: column;
    }
		
}