.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

p {
    width: 60%;
    text-align: left;
    padding: 15px;
}

.text-underline {
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    p {
        width: 90%;
    }
}