.filter-section {
    background-color: #291527;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #EEECF2;
    border-top: solid 1px #EEECF2;
}

.my-label {
    margin: auto;
    width: 40%;
    margin-right: 10px;
}

.my-select {
    background-color: #291527;
    color: #EEECF2;
    width: 100%;
}

.filter-container {
    display: flex;
    margin: auto;
    width: 25%;
}

.div-prod {
    padding-right: 20px;
}

.nw-prod {
    display: none;
}

@media only screen and (max-width: 768px) {
    .my-label {
        display: flex;
        flex-direction: row;
        width: auto;
        margin-bottom: 10px;
        margin-left: 0px;
        text-align: start;
    }

    .filter-container {
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
    }

    .div-prod {
        display: none;
    }

    .nw-prod {
        background-color: #291527;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px #EEECF2;
    }

}