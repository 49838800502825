.nw-banner {
	top: 0;
	z-index: 1000;
	height: 150px;
	text-align: right;
	border-bottom: solid 1px #EEECF2;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	background-color: black;
	transition: transform 0.3s ease-in-out;
}

.menu {
	display: contents;
}

.bars-menu {
	display: none;
}

.logo-web {
	height: inherit;
	cursor: pointer;
}

.nw-nav {
	margin: auto;
	cursor: pointer;
}

.nw-nav:hover {
	text-decoration: underline;
	color: #9D8099;
}

.nw-item-cover {
	height: 250px;
	width: 250px;
	border-radius: 20px;
	object-fit: cover;
}

.nw-item {
	margin: 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
	.logo-web {
		display: none;
	}

	.title {
		text-align: left;
		padding: 15px;
		font-size: large;
	}

	.nw-banner {
		margin-top: 30px;
		padding-bottom: 30px;
		height: 0%;
		text-align: right;
		border-bottom: solid 1px #EEECF2;
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;
		background-color: black;
		transition: transform 0.3s ease-in-out;
	}

	.nw-nav {
		display: none;
	}

	.menu-mobile {
		width: 100%;
		background-color: black;
		text-align: left;
		border-bottom: solid 1px #EEECF2;
		transition: transform 0.3s ease-in-out;
	}


	.nw-nav-mobile {
		margin-left: 15px;
		font-size: medium;
		cursor: pointer
	}

	.nw-nav-mobile:hover {
		font-size: medium;
		text-decoration: underline;
		color: #9D8099;
	}

	.logo-web {
		width: 50%;
	}

	.bars-menu {
		display: block;
		margin-left: 25px;
		border: none;
		color: #EEECF2;
		font-size: xx-large;
		background: none;
	}
}