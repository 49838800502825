.nw-cart {
	position: fixed;
	top: 0;
	right: 0;
	width: 30%;
	height: 100%;
	background: #291527;
	display: none;
	z-index: 1000;
}

.display-block {
	display: block;
}

.display-none {
	display: block;
}

.cart-list-container {
	max-height: 400px;
	/* ajustez la hauteur maximale en fonction de vos besoins */
	overflow-y: auto;
}

.nw-cart-header {
	color: #EEECF2;
	display: flex;
	padding-left: 20px;
}

.nw-cart-close {
	color: #EEECF2;
	display: flex;
	padding-left: 20px;
}

.nw-cart-products {
	display: flex;
	margin: auto;
	font-weight: bold;
}

.nw-cart-close:hover {
	color: #393D3A;
}

.nw-cart-total {
	display: flex;
	padding-left: 50px;
	border-top: solid 1px #EEECF2;
}

.nw-cart-add-button {
	margin-left: 32px;
	cursor: pointer;
}

.nw-cart-toggle-button {
	background: none;
	outline: none;
	border: none;
	font-weight: 600;
	cursor: pointer;
	align-self: self-start;
	padding: 10px;
}

.nw-cart-closed {
	padding: 32px;
}

.nw-item-cover-cart {
	height: 80px;
	width: 80px;
	border-radius: 20px;
	object-fit: cover;
}

.nw-cart-closed>button {
	font-size: 40px;
	color: #9D8099;
}

.nw-cart-closed>button:hover {
	color: #291527;
}

.nw-glob-cart {
	display: flex;
	flex-direction: column;
}

.nw-item-cart {
	display: flex;
	align-items: center;
	padding: 15px;
	font-weight: bold;
	margin-top: 15px;
}

.div-quantity {
	display: flex;
	align-items: center;
	height: 25px;
}

input {
	width: 50px;
}

.text-quantity {
	margin-right: 15px;
	margin-left: 15px;
}

.cart-footer {
	display: flex;
	justify-content: center;
	margin: 10px;
	flex-direction: column;
}

.button-remove {
	width: 30px;
	height: 30px;
	background-color: #9D8099;
	color: black;
	font-size: 16px;
	border: none;
	cursor: pointer;
	border-radius: 50%;
}

.button-quantity {
	width: 20px;
	height: 20px;
	margin: 5px;
	background-color: #9D8099;
	color: black;
	border: none;
	font-size: 12px;
	cursor: pointer;
	border-radius: 50%;
}

.button-quantity:hover {
	background-color: #393D3A;
	color: #EEECF2;
}

.button-valid {
	justify-content: center;
	background-color: #9D8099;
	color: black;
	font-size: 16px;
	padding: 10px 20px;
	border: 2px solid black;
	border-radius: 5px;
	cursor: pointer;
	margin: 5px;
	width: 100%;
}

.button-valid:hover {
	background-color: #393D3A;
	color: #EEECF2;
}

.cart-badge {
	background-color: #9D8099;
	color: black;
	border-radius: 50%;
	padding: 0.2em 0.5em;
	position: absolute;
	top: 62px;
	right: 10px;
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.nw-cart {
		width: 100%;
	}

	.nw-cart-closed {
		margin-left: 30px;
		padding: 20px;
	}

	.cart-badge {
		right: auto;
		margin-left: 50px;
		top: 18px;
	}

	.nw-cart-closed>button {
		font-size: 30px;
	}

}