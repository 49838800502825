.container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.button-add {
	transform: scale(1.1);
	cursor: pointer;
}

.button-add:hover {
	transform: scale(1.2);
	cursor: pointer;
}